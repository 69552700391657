import errorIcon from '@icons/error.svg';
import successIcon from '@icons/success.svg';
import timesIcon from '@icons/times.svg';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import styled from 'styled-components';

const NotificationWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 24px;
  right: ${({ show }) => (show ? '24px' : '-140%')};
  transition: 0.25s ease-in-out;
  background: ${({ theme }) => theme.colors.secondary.silver_1000};
  border: ${({ theme }) => `1px solid ${theme.colors.secondary.silver_900}`};
  box-shadow: 2px 6px 30px rgba(89, 89, 89, 0.12);
  border-radius: 2px;
  z-index: 90;
  width: 378px;
  height: 110px;
  padding: 20px;

  @media screen and (max-width: 420px) {
    width: 320px;
    height: 120px;
  }

  @media screen and (max-width: 360px) {
    width: 240px;
    height: 150px;
  }
`;

const NotificationTitle = styled.h4`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.secondary.silver_100};
  font-size: 18px;
  margin: 0;
`;

const TimesImg = styled.img`
  width: 10px;
  height: 10px;
  opacity: 0.5;
  transition: 0.2s linear;

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.2s linear;
  }
`;

const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NotificationHeaderBlock = styled.div`
  display: flex;
  gap: 12px;
`;

const NotificationText = styled.p`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.secondary.silver_400};
  font-size: 14px;
  line-height: 144%;
  padding-left: 32px;
  margin-bottom: 0;
`;

const notificationIcon = {
  success: successIcon,
  error: errorIcon,
};

const Notification = ({ notification, onClose }) => {
  const { t } = useTranslation('common');
  const { type, message } = notification;

  useEffect(() => {
    let timeout;

    if (type) {
      timeout = setTimeout(onClose, 3000);
    }

    return () => {
      clearInterval(timeout);
    };
  }, [notification]);

  return (
    <NotificationWrapper show={!!type}>
      <NotificationHeader>
        <NotificationHeaderBlock>
          <Image
            src={notificationIcon[type] || notificationIcon.error}
            alt={type}
            width={20}
            height={20}
          />
          <NotificationTitle>{t(`notification_${type}`)}</NotificationTitle>
        </NotificationHeaderBlock>
        <TimesImg onClick={onClose} src={timesIcon.src} alt="times" />
      </NotificationHeader>
      <NotificationText>{message}</NotificationText>
    </NotificationWrapper>
  );
};

export default Notification;
