import linkedIn from '@icons/linkedIn.svg';
import bg from '@icons/mail.svg';
import tg from '@icons/tg.svg';
import vk from '@icons/vk.svg';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  min-height: 37%;
  width: 100%;
  display: flex;
  position: relative;
  background: ${({ contacts, theme }) =>
    contacts
      ? `0% 100% / cover no-repeat url(${bg.src})`
      : theme.colors.primary.black};
  background-size: 72% 100%;

  @media screen and (max-width: 1070px) {
    background-size: 90% 100%;
  }

  @media screen and (max-width: 850px) {
    background-size: 105% 100%;
    background-position-x: 200%;
  }

  @media screen and (max-width: 700px) {
    background-size: 150% 90%;
    background-position-x: 10%;
  }

  @media screen and (max-width: 540px) {
    background-position-y: -20%;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 60px auto 40px;
  padding: 96px 0;
  flex-direction: ${({ contacts }) => (contacts ? 'row-reverse' : 'column')};
  justify-content: ${({ contacts }) => (contacts ? 'space-between' : 'normal')};
  gap: 40px;
  align-items: flex-start;

  @media screen and (max-width: 1400px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 520px) {
    padding: 0 20px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 120px;
  }

  @media screen and (min-width: 790px) {
    padding: 0 80px;
  }
`;

const FooterSocial = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  gap: 20px;

  @media screen and (max-width: 790px) {
    align-self: ${({ contacts }) => (contacts ? 'flex-end' : 'flex-start')};
  }
`;

const FooterLink = styled.a`
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.colors.primary.white};
  margin: 0;

  @media screen and (max-width: 790px) {
    line-height: 120%;
  }

  @media screen and (max-width: 490px) {
    font-size: 30px;
  }
`;

const SocialLink = styled.a`
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.secondary.silver_800};

  mask: url(${({ src }) => src}) no-repeat center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.orange};
  }
`;

const CopyrightBlock = styled.div`
  display: flex;
  align-self: ${({ contacts }) => (contacts ? 'flex-end' : 'auto')};
  gap: 5px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CopyrightText = styled.span`
  color: ${({ theme }) => theme.colors.secondary.silver_400};
`;

const CopyrightLink = styled.a`
  color: ${({ theme, contacts }) =>
    !contacts
      ? theme.colors.secondary.silver_900
      : theme.colors.secondary.silver_500};
  font-weight: 400;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.silver_800};
  }
  cursor: pointer;
`;

const Footer = ({ contacts }) => {
  const { t } = useTranslation('routes');
  const { locale } = useRouter();

  return (
    <StyledFooter contacts={contacts}>
      <FooterContainer contacts={contacts}>
        {!contacts ? (
          <FooterLink href="mailto:hello@fojin.tech">
            hello@fojin.tech
          </FooterLink>
        ) : null}
        <FooterSocial contacts={contacts}>
          {locale === 'ru' ? (
            <>
              <SocialLink
                href="https://vk.com/fojin"
                target="_blank"
                rel="noopener noreferrer"
                src={vk.src}
              />
              <SocialLink
                href="https://t.me/fojin_tech"
                target="_blank"
                rel="noopener noreferrer"
                src={tg.src}
              />
            </>
          ) : (
            <SocialLink
              href="https://linkedin.com/company/fojin"
              target="_blank"
              rel="noopener noreferrer"
              src={linkedIn.src}
            />
          )}
        </FooterSocial>
        <CopyrightBlock contacts={contacts}>
          <CopyrightText>© 2023 Fojin.</CopyrightText>
          <Link href="/policy" passHref>
            <CopyrightLink contacts={contacts}>{t('copyright')}</CopyrightLink>
          </Link>
        </CopyrightBlock>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
