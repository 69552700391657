import { useRouter } from 'next/router';
import { forwardRef, useId } from 'react';
import styled from 'styled-components';

import FileAdd from './assets/FileAdd';
import FileCross from './assets/FileCross';

const InputWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  right: 12px;
  bottom: -12px;
  max-height: 60px;
  pointer-events: none;

  @media screen and (max-width: 790px) {
    right: ${({ locale }) => (locale === 'ru' ? '3px' : '6px')};
    bottom: -22px;
  }
  @media screen and (max-width: 520px) {
    right: ${({ locale }) => (locale === 'ru' ? '1px' : '3px')};
    width: 45%;
    text-align: end;
  }
`;

const Input = styled.input`
  appearance: none;
  display: none;
`;

const Label = styled.label`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.secondary.silver_500};
  display: flex;
  align-items: center;
  gap: 15px;
  transition: 0.2s linear;
  margin-bottom: 25px;
  pointer-events: all;

  @media screen and (max-width: 790px) {
    margin-bottom: 20px;
  }

  svg path {
    transition: 0.2s linear;
  }

  &:hover {
    cursor: pointer;

    svg path {
      fill: ${({ theme }) => theme.colors.secondary.silver_900};
      transition: 0.2s linear;
    }
  }
`;

const StyledError = styled.span`
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary.red};
  height: 23px;
  opacity: ${({ errors }) => (errors ? 1 : 0)};
  transition: 0.2s linear;
`;

const Times = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.secondary.silver_500};
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  pointer-events: all;

  svg path {
    fill: ${({ theme }) => theme.colors.secondary.silver_500};
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.secondary.silver_900};
    transition: 0.2s linear;

    svg path {
      fill: ${({ theme }) => theme.colors.secondary.silver_900};
      transition: 0.2s linear;
    }
  }
`;

const Fileinput = (
  { fileName, resetField, name, onChange, onBlur, errors = false, errorText },
  ref,
) => {
  const fileInputId = useId();
  const { locale } = useRouter();

  return (
    <InputWrapper locale={locale}>
      <Label htmlFor={fileInputId}>
        <FileAdd onClick={() => resetField('file')} />
      </Label>
      {fileName && (
        <Times>
          {fileName}
          <div onClick={() => resetField('file')}>
            <FileCross />
          </div>
        </Times>
      )}
      <Input
        id={fileInputId}
        type="file"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        errors={errors}
      />
      <StyledError errors={errors}>{errorText}</StyledError>
    </InputWrapper>
  );
};

export default forwardRef(Fileinput);
