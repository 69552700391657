const FileCross = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.17436 6.99951L3.69948 4.52464L4.52444 3.69968L6.99932 6.17455L9.47419 3.69968L10.2991 4.52464L7.82427 6.99951L10.2991 9.47439L9.47419 10.2993L6.99932 7.82447L4.52444 10.2993L3.69948 9.47439L6.17436 6.99951Z"
      fill="currentColor"
    />
  </svg>
);

export default FileCross;
