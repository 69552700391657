import styled, { keyframes } from 'styled-components';

const spinerCycle = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  width: 19px;
  height: 19px;
  background: transparent;
  border-top: 4px solid ${({ theme }) => theme.colors.secondary.silver_1000};
  border-right: 4px solid ${({ theme }) => theme.colors.secondary.silver_1000};
  border-left: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 50%;
  animation: ${spinerCycle} 1s linear infinite;
`;

export default Loader;
