import { forwardRef, useId } from 'react';
import styled from 'styled-components';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px 0 0;
  gap: 2px;
  width: 100%;
  margin-top: 3px;
  @media screen and (max-width: 790px) {
    &:not(&:first-child) {
      margin-top: 33px;
    }
  }
`;

const StyledLabel = styled.label`
  pointer-events: none;
  cursor: text;
  position: absolute;
  display: block;
  color: ${({ theme }) => theme.colors.secondary.silver_30};
  font-weight: 500;
  transition: 0.2s;
  line-height: 34px;
  font-size: ${({ inputValue }) => (inputValue ? '12px' : '24px')};
  top: ${({ inputValue }) => (inputValue ? '-8px' : '16px')};
`;

const StyledError = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary.red};
  height: 23px;
  opacity: ${({ errors }) => (errors ? 1 : 0)};
  transition: 0.2s linear;
`;

const StyledInput = styled.input`
  border: none;
  border-bottom: ${({ errors, theme }) =>
    `1px solid ${
      errors ? theme.colors.primary.red : theme.colors.secondary.silver_500
    }`};
  color: ${({ errors, theme }) =>
    errors ? theme.colors.primary.red : theme.colors.secondary.silver_30};
  background: transparent;
  line-height: 32px;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.02em;
  border-radius: 0;
 


  &::placeholder {
    color: transparent;
  }

  &:hover {
    border-color: ${({ errors, theme }) =>
      errors ? theme.colors.primary.red : theme.colors.secondary.silver_900};

      & + ${StyledLabel} {
        color: ${({ theme }) => theme.colors.secondary.silver_900};
      }
    }

    &::placeholder {
      color: transparent;
    }
  }

  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.secondary.silver_900};
    outline: 0;
    border-color: currentColor;
    transition: 0.2;

    & + ${StyledLabel} {
      font-size: 12px;
      top: -8px;
      color: ${({ theme }) => theme.colors.primary.orange};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.primary.black};
    }
  }

  &:-webkit-autofill {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;
const Input = (
  {
    label,
    placeholder,
    name,
    errorText,
    errors = false,
    onChange,
    onBlur,
    watch,
  },
  ref,
) => {
  const inputId = useId();
  const inputValue = watch(name);

  return (
    <InputWrapper>
      <StyledInput
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        id={inputId}
        placeholder={placeholder}
        errors={errors}
        inputValue={inputValue}
      />
      <StyledLabel errors={errors} htmlFor={inputId} inputValue={inputValue}>
        {label}
      </StyledLabel>
      <StyledError errors={errors}>{errorText}</StyledError>
    </InputWrapper>
  );
};

export default forwardRef(Input);
