import styled from 'styled-components';

const StyledSection = styled.section`
  width: 100vw;
  padding: 180px 0;

  @media screen and (max-width: 790px) {
    padding: 120px 0;
  }

  @media screen and (max-width: 520px) {
    padding: 90px 0;
  }
`;

const Section = ({ children, ...props }) => (
  <StyledSection {...props}>{children}</StyledSection>
);

export default Section;
