import ky from 'ky';

require('dotenv').config();

const API_PREFIX = process.env.API_PREFIX || process.env.NEXT_PUBLIC_API_PREFIX;

const api = ky.create({
  prefixUrl: API_PREFIX,
});

export default api;
