import { useTranslation } from 'next-i18next';
import { forwardRef, useId } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

const TextAreaWrapper = styled.div`
  margin-bottom: 4px;
  width: 100%;
  min-height: 92px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 620px) {
    min-height: 110px;
  }

  @media screen and (max-width: 380px) {
    min-height: 145px;
  }
`;

const TextAreaLabel = styled.label(({ inputvalue, theme }) => ({
  pointerEvents: 'none',
  cursor: 'text',
  position: 'absolute',
  top: inputvalue ? '-23px' : null,
  display: 'block',
  color: theme.colors.secondary.silver_500,
  fonWeight: 500,
  transition: '0.2s',
  lineHeight: '34px',
  letterSpacing: '0.04em',
  fontSize: inputvalue ? '12px' : '24px',
  fontWeight: 500,
  bottom: inputvalue ? '34px' : '3px',

  '@media screen and (max-width: 475px)': {
    fontSize: inputvalue ? '12px' : '16px',
  },
}));

const TextAreaError = styled.span`
  font-size: 12px;
  height: 23px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary.red};
  opacity: ${({ errors }) => (errors ? 1 : 0)};
  transition: 0.2s linear;
`;

const TextAreaSubscript = styled.span`
  padding-top: 2px;
  font-size: 12px;
  height: 23px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.colors.secondary.silver_500};

  @media screen and (max-width: 620px) {
    max-width: 200px;
    height: 32px;
  }

  @media screen and (max-width: 375px) {
    max-width: 125px;
    height: 48px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const TextArea = styled(TextareaAutosize)`
  position: relative;
  top: 5px;
  max-height: ${({ inputvalue }) => (inputvalue ? null : '39px')};
  width: 100%;
  padding-right: 52px;
  resize: none;
  border: none;
  border-bottom: ${({ errors, theme }) =>
    `1px solid ${
      errors ? theme.colors.primary.red : theme.colors.secondary.silver_500
    }`};
  color: ${({ errors, theme }) =>
    errors ? theme.colors.primary.red : theme.colors.secondary.silver_900};
  background: transparent;
  line-height: 34px;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.04em;
  border-radius: 0;

  &::placeholder {
    color: transparent;
  }

  &:hover {
    border-color: ${({ errors, theme }) =>
      errors ? theme.colors.primary.red : theme.colors.secondary.silver_900};

    & + ${TextAreaLabel} {
      color: ${({ theme }) => theme.colors.secondary.silver_900};
    }

    &::placeholder {
      color: transparent;
    }
  }

  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.secondary.silver_900};
    outline: 0;
    border-color: currentColor;
    transition: 0.2;

    & + ${TextAreaLabel} {
      font-size: 12px;
      bottom: 34px;
      color: ${({ theme }) => theme.colors.primary.orange};
    }
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary.black};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ inputvalue, theme }) =>
      inputvalue
        ? theme.colors.secondary.silver_500
        : theme.colors.primary.black};
    width: 3px;
    height: 5px;
  }

  @media screen and (max-width: 790px) {
    padding-right: 32px;
  }

  @media screen and (max-width: 455px) {
    max-height: ${({ inputvalue }) => (inputvalue ? null : '39px')};
  }
`;

const TextAreaAutosize = (
  {
    label,
    placeholder,
    name,
    errorText,
    errors = '',
    onChange,
    onBlur,
    watch,
    ...props
  },
  ref,
) => {
  const textAreaId = useId();
  const inputValue = watch(name);
  const { t } = useTranslation('common');

  return (
    <TextAreaWrapper>
      <Container>
        <TextArea
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          id={textAreaId}
          placeholder={placeholder}
          errors={errors}
          minRows={1}
          maxRows={5}
          inputvalue={inputValue}
        />
        <TextAreaLabel
          errors={errors}
          htmlFor={textAreaId}
          inputvalue={inputValue}
        >
          {label}
        </TextAreaLabel>
        {props.children}
      </Container>
      {errors ? (
        <TextAreaError errors={errors}>{errorText}</TextAreaError>
      ) : (
        <>
          <TextAreaSubscript>{t('about_subscript')}</TextAreaSubscript>
          <TextAreaSubscript>{t('about_subscript_fileSize')}</TextAreaSubscript>
        </>
      )}
    </TextAreaWrapper>
  );
};

export default forwardRef(TextAreaAutosize);
