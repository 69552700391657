import CheckBox from '@components/ContactsSection/modules/CheckBox';
import api from '@services/api';
import Loader from '@ui/Loader';
import Notification from '@ui/Notification';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import Fileinput from './FileInput';
import Input from './Input';
import TextAreaАutosize from './TextAreaАutosize';

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 13px;
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 90px;

  @media screen and (max-width: 520px) {
    &:last-child {
      margin-top: 32px;
    }
  }

  @media screen and (max-width: 790px) {
    column-gap: 110px;
  }

  @media screen and (max-width: 1024px) {
    align-items: center;
    flex-direction: column;
    &:last-child {
      margin-top: 13px;
    }
  }

  @media screen and (max-width: 1100px) {
    column-gap: 50px;
  }

  @media screen and (min-width: 1400px) {
    column-gap: 90px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 343px;

  @media screen and (max-width: 790px) {
    max-width: 710px;
    margin-top: 30px;
  }
`;

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  padding: 14px 30px;
  border: ${({ theme }) => `1px solid ${theme.colors.secondary.silver_500}`};
  border-radius: 39px;
  outline: 0;
  transition: 0.3s linear;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.secondary.silver_40};
  font-weight: 400;
  align-self: flex-end;
  width: 100%;
  min-height: 64px;

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.secondary.silver_1000};
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.secondary.silver_900};
  }

  @media screen and (max-width: 790px) {
    margin-bottom: 13px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary.white};
  }
`;

const Form = () => {
  const { t } = useTranslation(['common', 'contacts']);
  const [agree, setAgree] = useState(false);
  const [notification, setNotification] = useState({ type: null, message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    watch,
    setError,
    clearErrors,
    setValue,
    ref,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      check: false,
    },
  });
  const { locale } = useRouter();

  const sendMessage = async (data) => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('about', data.about);

    if (data.file) {
      formData.append('attachment', data.file[0]);
    }

    try {
      await api.post('contact', { body: formData });
      const message = t('submit_success');
      setNotification({ type: 'success', message });
      reset();
    } catch (error) {
      const message = t('submit_error');
      setNotification({ type: 'error', message });
    } finally {
      setIsLoading(false);
    }
  };

  const fileName = watch('file');

  const onSubmit = () => {
    sendMessage(getValues());
  };

  const handleCheck = (e) => {
    setValue('check', e);
    setAgree(e);
  };

  return (
    <>
      <Notification
        onClose={() => setNotification({ type: null, message: '' })}
        notification={notification}
      />
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <RowWrapper>
          <Input
            {...register('name', {
              required: t('error'),
              maxLength: {
                value: 100,
                message: t('error_name', { ns: 'contacts' }),
              },
            })}
            label={t('name_input')}
            placeholder={t('name_placeholder')}
            errors={errors.name}
            errorText={errors.name?.message}
            watch={watch}
          />
          <Input
            {...register('email', {
              required: t('error'),
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: t('error_email'),
              },
            })}
            label={t('email_input')}
            placeholder={t('email_placeholder')}
            errors={errors.email}
            errorText={errors.email?.message}
            watch={watch}
          />
          <Input
            {...register('phone', {
              maxLength: {
                value: 100,
                message: t('error_phone_length', { ns: 'contacts' }),
              },
            })}
            label={t('phone_input')}
            placeholder={t('phone_placeholder')}
            errors={errors.phone}
            errorText={errors.phone?.message}
            watch={watch}
          />
        </RowWrapper>
        <RowWrapper>
          <TextAreaАutosize
            {...register('about', {
              required: t('error'),
              maxLength: {
                value: 1000,
                message: t('error_about', { ns: 'contacts' }),
              },
            })}
            label={t('about_input_label')}
            placeholder={t('about_placeholder')}
            errors={errors.about}
            errorText={errors.about?.message}
            watch={watch}
          >
            <Fileinput
              {...register('file', {
                onChange: () => {
                  const { file } = getValues();
                  if (file[0]?.size > 25e6) {
                    setError('file', {
                      type: 'custom',
                      message: t('error_file_size'),
                    });
                  }
                },
                validate: (file) =>
                  file?.length &&
                  (file[0]?.size < 25e6 || t('error_file_size')),
              })}
              clearErrors={clearErrors}
              resetField={resetField}
              fileName={fileName?.[0]?.name}
              text={t('file')}
              errors={errors.file}
              errorText={errors.file?.message}
              watch={watch}
            />
          </TextAreaАutosize>
          <ButtonContainer locale={locale}>
            <SubmitButton disabled={isLoading} type="submit">
              {isLoading ? <Loader /> : t('cta')}
            </SubmitButton>
            <CheckBox
              {...register('check', {
                validate: (value) => value || t('error_accept'),
              })}
              link={t('accept_link')}
              text={t('accept_text')}
              ref={ref}
              checked={agree}
              error={errors.check?.message}
              onChange={handleCheck}
            />
          </ButtonContainer>
        </RowWrapper>
      </FormWrapper>
    </>
  );
};

export default Form;
