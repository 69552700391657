import Link from 'next/link';
import { useId } from 'react';
import styled from 'styled-components';

import check from '../assets/check.svg';

const CheckboxLabel = styled.label`
  font-weight: 400;
  line-height: 144%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 0 0.7ex;

  a {
    font-size: 12px;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  gap: 0.875rem;
  position: relative;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckBox = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${({ checked, theme }) =>
    checked ? theme.colors.primary.orange : theme.colors.secondary.silver_100};
  border-radius: 2px;
`;

const CheckBoxMark = styled.span`
  visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  width: 19px;
  height: 19px;
  background: ${({ theme }) => theme.colors.primary.orange};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckBoxIcon = styled.img`
  width: 14px;
  height: 10px;
`;

const PrivacyText = styled.span`
  color: ${({ theme }) => theme.colors.secondary.silver_40};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
`;

const PrivacyLink = styled.a`
  color: ${({ theme }) => theme.colors.primary.orange};
  transition: color 0.2s linear;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary.orange_700};
  }
`;

const StyledError = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary.red};
  height: 23px;
  transition: 0.2s linear;
  position: absolute;
  bottom: -20px;
`;

const CheckBox = ({ checked, text, link, onChange, error }) => {
  const checkboxId = useId();

  return (
    <CheckBoxWrapper>
      <HiddenCheckbox
        id={checkboxId}
        onChange={(event) => onChange(event.target.checked)}
        checked={checked}
      />
      <StyledCheckBox onClick={() => onChange(!checked)} checked={checked}>
        <CheckBoxMark checked={checked}>
          <CheckBoxIcon src={check.src} alt="check" />
        </CheckBoxMark>
      </StyledCheckBox>
      <CheckboxLabel>
        <PrivacyText>
          {text}{' '}
          <Link href="/policy" passHref>
            <PrivacyLink>{link}</PrivacyLink>
          </Link>
        </PrivacyText>
        {error ? <StyledError>{error}</StyledError> : null}
      </CheckboxLabel>
    </CheckBoxWrapper>
  );
};

export default CheckBox;
